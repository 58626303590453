import Helmet from "react-helmet";
import React from "react";
import {
  SiteNavigationElementSchema,
  FoodEstablishmentSchema,
  MessageDisplay,
  SiteNavigation,
  HoursOfOperation,
  RecaptchaProvider,
  Footer,
  SocialMedia,
  Image,
  SiteMap,
  CopyrightLabel,
  OutboundLink,
  FishermanBanner,
} from "@bluefin/components";
import { Header } from "semantic-ui-react";
import { graphql, StaticQuery } from "gatsby";

import {
  getComponentFiles,
  filterRegularLinks,
  filterButtonLinks,
} from "../utils/utils";
import "../semantic/semantic.less";
import "../style/WebsiteLayout.css";

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allFishermanBusinessWebsitePage(
          sort: { order: ASC, fields: navigationPriority }
        ) {
          nodes {
            slug
            description
            title
            pageType
            url
            navigationPriority
            groupName
            props
          }
        }
        fishermanBusiness {
          name
          type
          logo
          menu {
            schedules {
              availableDays
              categories
              _id
              name
            }
            categories {
              items
              name
              _id
            }
            items {
              modifierSets
              description
              _id
              name
              variations {
                _id
                menuItem
                name
                order
                price
              }
            }
            modifierSets {
              _id
              minAllowed
              modifiers
              name
            }
            modifiers {
              _id
              name
              price
            }
          }
          primaryLocation {
            timezone
            hours {
              day
              open
              close
              label
            }
          }
          gatsbyLogo {
            childImageSharp {
              gatsbyImageData(layout: FIXED, height: 60)
            }
          }
          socialMedia {
            link
            type
          }
        }
        allFishermanBusinessLocation {
          nodes {
            street
            city
            state
            zipCode
            country
            email
            phoneNumber
            name
            hours {
              open
              close
              day
            }
          }
        }
        fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
          components {
            fastId
            componentIdentifier
            order
            files {
              file
              gatsbyFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
        fishermanWebsiteComponent(
          layout: { name: { eq: "@" } }
          fastId: { eq: "MessageDisplay" }
        ) {
          data {
            subject
            modalDelay
            bannerBackgroundColor
          }
        }
      }
    `}
    render={(data) => {
      const {
        allFishermanBusinessWebsitePage,
        fishermanBusiness,
        allFishermanBusinessLocation,
        fishermanBusinessWebsitePage,
        fishermanWebsiteComponent,
      } = data;

      return (
        <React.Fragment>
          <SiteNavigationElementSchema
            pages={allFishermanBusinessWebsitePage.nodes}
            siteUrl={"https://www.nails2tailsgrooming.com"}
          />
          <FoodEstablishmentSchema
            name={fishermanBusiness.name}
            siteUrl={"https://www.nails2tailsgrooming.com"}
            businessType={fishermanBusiness.type}
            logo={fishermanBusiness.logo}
            address={allFishermanBusinessLocation.nodes}
            menu={fishermanBusiness.menu}
            images={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              numToSelect: 1,
            })}
          />
          <div className={"site-container"}>
            <div>
              <MessageDisplay
                subject={fishermanWebsiteComponent.data.subject}
                businessName={fishermanBusiness.name}
                modalDelay={fishermanWebsiteComponent.data.modalDelay}
                bannerBackgroundColor={
                  fishermanWebsiteComponent.data.bannerBackgroundColor
                }
                timezone={fishermanBusiness.primaryLocation.timezone}
              />
              <SiteNavigation widths={3}>
                <SiteNavigation.Menu position={"left"}>
                  <SiteNavigation.Links
                    links={filterRegularLinks({
                      links: allFishermanBusinessWebsitePage.nodes,
                    })}
                  />
                </SiteNavigation.Menu>
                <SiteNavigation.Logo
                  title={<Header as={"h3"}>{fishermanBusiness.name}</Header>}
                  src={fishermanBusiness.gatsbyLogo.childImageSharp}
                />
                <SiteNavigation.Menu position={"right"}>
                  <SiteNavigation.Item position={"right"}>
                    <HoursOfOperation
                      timezone={fishermanBusiness.primaryLocation.timezone}
                      hours={fishermanBusiness.primaryLocation.hours}
                      displayOption={"dailyWithPopup"}
                      displayOpenClosedStatus={true}
                    />
                  </SiteNavigation.Item>
                  <SiteNavigation.Links
                    links={filterButtonLinks({
                      links: allFishermanBusinessWebsitePage.nodes,
                    })}
                  />
                </SiteNavigation.Menu>
              </SiteNavigation>
            </div>
            <RecaptchaProvider>
              <div className={"page-container"}>{children}</div>
              <Footer
                backgroundColor={"primary"}
                horizontalAlign={"center"}
                verticalAlign={"middle"}
                columns={[
                  <div>
                    <div className={"social-medias-container"}>
                      <div>
                        <SocialMedia
                          socialMediaValues={fishermanBusiness.socialMedia}
                          buttonType={"circle"}
                          centerHeader={false}
                          colors={"secondary"}
                          groupButtons={false}
                          groupVertically={false}
                          header={
                            <Header as={"div"} className={"h6"}>
                              Edinburg Location
                            </Header>
                          }
                          inverted={false}
                          labelPosition={"free"}
                          showLabels={false}
                        />
                        <a
                          target={"_blank"}
                          href={
                            "https://www.tiktok.com/@nails2tailsgroomingrgv?_t=8b6qyAQnYS6&_r=1"
                          }
                          className={"ui circular icon secondary button"}
                          role={"button"}
                        >
                          <Image
                            size={"tiny"}
                            centered={true}
                            as={"i"}
                            src={
                              "https://fisherman.gumlet.io/public/b2e5e97c-ce2d-4b86-a594-eced6c4c5629/tiktok.png"
                            }
                          />
                        </a>
                      </div>
                    </div>
                    <React.Fragment />
                  </div>,
                  <div>
                    <Image src={fishermanBusiness.gatsbyLogo.childImageSharp} />
                    <SiteMap
                      links={allFishermanBusinessWebsitePage.nodes}
                      horizontal={true}
                      bulleted={false}
                    />
                    <CopyrightLabel
                      company={fishermanBusiness.name}
                      phrase={"All Rights Reserved"}
                      privacyPolicyLink={
                        <OutboundLink
                          to={
                            "https://fisherman-static.s3.amazonaws.com/public/__fisherman/Client+Website+Privacy+Policy.pdf"
                          }
                          label={"Privacy Policy"}
                          eventLabel={"copyright-privacy-policy"}
                        />
                      }
                    />
                  </div>,
                  <div>
                    <div className={"social-medias-container"}>
                      <div>
                        <SocialMedia
                          socialMediaValues={[
                            {
                              type: "instagram",
                              link: "https://www.instagram.com/nails2tailsmission/",
                            },
                            {
                              type: "facebook",
                              link: "https://www.facebook.com/nails2tailsmission/",
                            },
                          ]}
                          buttonType={"circle"}
                          centerHeader={false}
                          colors={"secondary"}
                          groupButtons={false}
                          groupVertically={false}
                          header={
                            <Header as={"div"} className={"h6"}>
                              Mission Location
                            </Header>
                          }
                          inverted={false}
                          labelPosition={"free"}
                          showLabels={false}
                        />
                        <a
                          target={"_blank"}
                          href={
                            "https://www.tiktok.com/@nails2tailsgroomingrgv?_t=8b6qyAQnYS6&_r=1"
                          }
                          className={"ui circular icon secondary button"}
                          role={"button"}
                        >
                          <Image
                            size={"tiny"}
                            centered={true}
                            as={"i"}
                            src={
                              "https://fisherman.gumlet.io/public/b2e5e97c-ce2d-4b86-a594-eced6c4c5629/tiktok.png"
                            }
                          />
                        </a>
                      </div>
                    </div>
                    <React.Fragment />
                  </div>,
                ]}
              />
            </RecaptchaProvider>
          </div>
          <FishermanBanner />
          <Helmet>
            <link
              rel={"stylesheet"}
              href={
                "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/fontawesome.min.css"
              }
              integrity={
                "sha512-RvQxwf+3zJuNwl4e0sZjQeX7kUa3o82bDETpgVCH2RiwYSZVDdFJ7N/woNigN/ldyOOoKw8584jM4plQdt8bhA=="
              }
              crossorigin={"anonymous"}
              referrerpolicy={"no-referrer"}
            />
            <link
              rel={"stylesheet"}
              href={
                "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/brands.min.css"
              }
              integrity={
                "sha512-+oRH6u1nDGSm3hH8poU85YFIVTdSnS2f+texdPGrURaJh8hzmhMiZrQth6l56P4ZQmxeZzd2DqVEMqQoJ8J89A=="
              }
              crossorigin={"anonymous"}
              referrerpolicy={"no-referrer"}
            />
            <meta
              name={"facebook-domain-verification"}
              content={"afmorj3lgqrbvmwbdt3so3q239zcml"}
            />
          </Helmet>
        </React.Fragment>
      );
    }}
  />
);
